import moment from "moment";
import * as yup from "yup";
const editPolicySchema = yup.object().shape({
  prev_ncb: yup.string().nullable().when(
    ["is_claim_made_last_year", "policy_expired_before_90_days"],
    {
      is: (is_claim_made_last_year, policy_expired_before_90_days) =>
        is_claim_made_last_year === "N" &&
        policy_expired_before_90_days === "N",
      then: yup.string().required("Previous NCB is required"),
      otherwise: yup.string().notRequired(),
    }
  ),

  prev_policy_type: yup.string().required(),

  manf_month: yup.string()
    .required("Manufacturing Month is required")
    .test("is-current-month", "Manufacturing Month cannot be the current month", function (val) {
      return moment(val, "YYYY-MM").isBefore(moment().startOf('month'));
    })
    .test("manf_month", "Please enter a valid date", function (val) {
      return moment(val, "YYYY-MM").isValid();
    })
    // .test(
    //   "manf_month",
    //   "Manufacturing Month should be before expiry",
    //   function (val) {
    //     return moment(val, "YYYY-MM").isBefore(
    //       moment(this.parent.registration_date, "YYYY-MM-DD")
    //     );
    //   }
    // )
    .test(
      "manf_month",
      "Manufacturing Month should be before registration date",
      function (val) {
        return moment(val, "YYYY-MM").isBefore(
          moment(this.parent.registration_date, "YYYY-MM-DD")
        );
      }
    )
    .test(
      "manf_month",
      "Manufacturing Month and Registration Date should be within 1 year",
      function (val) {
        return (
          moment(this.parent.registration_date, "YYYY-MM-DD").diff(
            moment(val, "YYYY-MM"),
            "days"
          ) <= 365
        );
      }
    ), 
  registration_date: yup
    .string()
    .required("Registration Date Required")
    .test(
      "registration_date",
      "Registration Date should be before expiry",
      function (val) {
        return moment(val, "YYYY-MM-DD").isBefore(
          moment(this.parent.existing_exp_date, "YYYY-MM-DD")
        );
      }
    ),
  is_claim_made_last_year: yup.string().required(),
});
export default editPolicySchema;
