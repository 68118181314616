import * as Yup from 'yup';
import moment from 'moment';

const validation = Yup.object().shape({
  is_ckyc_no: Yup.string().required('Please specify if you have a CKYC number'),

  ckyc_no: Yup.string().when('is_ckyc_no', {
    is: 'Y',
    then: Yup.string()
      .required('CKYC number is required')
      .length(14, 'CKYC number must be exactly 14 digits'),
    otherwise: Yup.string().nullable(),
  }),

  ckyc_dob: Yup.date().when(['is_ckyc_no', 'product'], {
    is: (is_ckyc_no, product) => is_ckyc_no === 'Y' && product?.INSURANCE_SLUG === "icici_lombard_general",
    then: Yup.date()
      .required('CKYC Date of Birth is required')
      .max(moment().subtract(18, 'years'), 'Must be at least 18 years old')
      .min(moment().subtract(60, 'years'), 'Date of Birth cannot be more than 60 years ago')
      .typeError('Invalid date format'),
    otherwise: Yup.date().nullable(),
  }),
  method_of_verification: Yup.string().when('is_ckyc_no', {
    is: 'N',
    then: Yup.string().required('Method of Verification is required'),
    otherwise: Yup.string().nullable(),
  }),

  ckyc_pan_no: Yup.string().when('method_of_verification', {
    is: 'pancard',
    then: Yup.string()
      .required('PAN Number is required')
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN format'),
    otherwise: Yup.string().nullable(),
  }),

  pan_dob: Yup.date().when('method_of_verification', {
    is: 'pancard',
    then: Yup.date()
      .required('Date of Birth for PAN is required')
      .max(moment().subtract(18, 'years'), 'You must be at least 18 years old')
      .min(moment().subtract(60, 'years'), 'Date of Birth cannot be more than 60 years ago')
      .typeError('Invalid date format'),
    otherwise: Yup.date().nullable(),
  }),

  aadhaar_number: Yup.string().when('method_of_verification', {
    is: 'aadharcard',
    then: Yup.string()
      .required('Aadhaar Number is required')
      .length(12, 'Aadhaar number must be 12 digits'),
    otherwise: Yup.string().nullable(),
  }),

  full_name: Yup.string().when('method_of_verification', {
    is: 'aadharcard',
    then: Yup.string().required('Aadhar Name is required'),
    otherwise: Yup.string().nullable(),
  }),

  aadhaar_dob: Yup.date().when('method_of_verification', {
    is: 'aadharcard',
    then: Yup.date()
      .required('Date of Birth for Aadhaar is required')
      .max(moment().subtract(18, 'years'), 'You must be at least 18 years old')
      .min(moment().subtract(60, 'years'), 'Date of Birth cannot be more than 60 years ago')
      .typeError('Invalid date format'),
    otherwise: Yup.date().nullable(),
  }),

  ckyc_gender: Yup.string().when('method_of_verification', {
    is: 'aadharcard',
    then: Yup.string().required('Gender is required'),
    otherwise: Yup.string().nullable(),
  }),

  dl_number: Yup.string().when('method_of_verification', {
    is: 'drivingdetails',
    then: Yup.string()
      .required('Driving License Number is required')
      .matches(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/, 'Invalid Driving License format'),
    otherwise: Yup.string().nullable(),
  }),

  dl_dob: Yup.date().when('method_of_verification', {
    is: 'drivingdetails',
    then: Yup.date()
      .required('Date of Birth for Driving License is required')
      .max(moment().subtract(18, 'years'), 'You must be at least 18 years old')
      .min(moment().subtract(60, 'years'), 'Date of Birth cannot be more than 60 years ago')
      .typeError('Invalid date format'),
    otherwise: Yup.date().nullable(),
  }),

  file_number: Yup.string().when('method_of_verification', {
    is: 'passportdetails',
    then: Yup.string()
      .required('Passport Number is required')
      .length(12, 'Passport number must be 12 digits')
      .matches(/^[A-Z0-9.,/ $@()]+$/, 'Enter correct Passport number format'),
    otherwise: Yup.string().nullable(),
  }),

  passport_dob: Yup.date().when('method_of_verification', {
    is: 'passportdetails',
    then: Yup.date()
      .required('Date of Birth for Passport is required')
      .max(moment().subtract(18, 'years'), 'You must be at least 18 years old')
      .typeError('Invalid date format'),
    otherwise: Yup.date().nullable(),
  }),
});

export default validation;
