import {
  CircularProgress,
  Dialog,
  Divider,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../../components/Button/Button";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import { yesNoItems } from "../../../../utils/YesNoRadioItems";
import "styled-components/macro";
import { useQueryClient } from "react-query";
import {
  ButtonsWrapper,
  CloseButton,
  DialogTitle,
  FormWrapper,
  InputWrapper,
} from "../EditDetailsDialog/EditDetailsDialog.style";
import { useEnquiryQuery } from "../../../../services/CarServices";
import { useFilterMutation } from "../../../../services/QuoteServices";
import { useNavigate } from "react-router-dom";
import NCB from "./components/NCB";
import editPolicySchema from "./validations";
import {useProposalEnquiry} from "../../../../services/ProposalServices";
// import { useAddonsFromUrl } from "../../../../hooks/addonsFromUrl";
const useStyles = makeStyles({
  paper: {
    padding: "0 12px",
  },
});
const EditPolicyDetails = ({ open, onClose }) => {
  const fullScreen = useMediaQuery("(max-width:576px)");
  const { requestData } = useEnquiryQuery();
  const classes = useStyles();
  const { product } = useProposalEnquiry();
  const reg_date = requestData?.registration_date?.split("-");

  const [formData, setFormData] = useState({
    existing_exp_date: requestData?.existing_exp_date,
    is_claim_made_last_year: requestData?.is_claim_made_last_year,
    prev_policy_type: "COMP",
    manf_month: "",
    prev_ncb: 20,
  });
  useEffect(() => {
    setFormData({
      existing_exp_date: requestData?.existing_exp_date,
      is_claim_made_last_year: requestData?.is_claim_made_last_year,
      prev_policy_type: requestData?.prev_policy_type || "COMP",
      manf_month:
        requestData?.manf_month ||
        (reg_date && reg_date[0] + "-" + reg_date[1]),
      prev_ncb: requestData?.prev_ncb,
      registration_date: requestData?.registration_date,
      policy_expired_before_90_days: requestData?.policy_expired_before_90_days,
    });
  }, [requestData]);
  const {
    existing_exp_date,
    is_claim_made_last_year,
    prev_policy_type,
    manf_month,
    registration_date,
  } = formData;

  const navigate = useNavigate();
  const { update, isLoading, isSuccess, quote_id } = useFilterMutation(true);
  const queryClient = useQueryClient();
  // const prevAddon = useAddonsFromUrl(true);
  console.log(isSuccess, quote_id);
  console.log(product,"productproductproductproductproductproductproductproduct")
  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, quote_id]);
  const [errors, setErrors] = useState();

  const onChangeValidate = async (name, data) => {
    setFormData({ ...formData, [name]: data });
    editPolicySchema
      .validateAt(name, {
        ...formData,
        [name]: data,
      })
      .then(() => {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      })
      .catch((error) => {
        setErrors((prev) => ({ ...prev, [name]: error.message }));
      });
  };
  const onSubmit = () => {
    console.log(product,"productproductproduct")
    editPolicySchema
    .validate(formData, { abortEarly: false,context: { product_code: product?.PRODUCT_CODE }, })
    .then(() => {
        update({ ...requestData, ...formData });
      })
      .catch((error) => {
        error.inner.forEach((item) => {
          setErrors((prev) => ({ ...prev, [item.path]: item.message }));
        });
      });
  };
  console.log(errors);
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={"sm"}
        fullScreen={fullScreen}
        classes={classes}
        disableEnforceFocus
      >
        <DialogTitle>
          <div>Edit Existing Policy Details</div>
          <CloseButton onClick={() => onClose()}>
            <Close />
          </CloseButton>
        </DialogTitle>
        <Divider />
        <FormWrapper>
          <InputWrapper>
            <RadioButton
              items={[
                { label: "Comprehensive / SAOD", value: "COMP" },
                { label: "Third Party", value: "TP" },
              ]}
              label={"Existing Policy Type"}
              defaultValue={prev_policy_type}
              onChange={(val) => {
                setFormData({ ...formData, prev_policy_type: val });
              }}
            />
          </InputWrapper>
          <CustomWrapper>
            {requestData?.registration_date && moment(requestData.registration_date).year() !== moment().year() && (
              <>
            <InputWrapper>
              <DatePicker
                label={"Policy Expiry Date"}
                size={"medium"}
                maxDate={moment(moment().add(90, "days"))}
                value={existing_exp_date || requestData?.existing_exp_date}
                handleChange={(val) => {
                  setFormData({ ...formData, existing_exp_date: val });
                  onChangeValidate("existing_exp_date", val);
                }}
                error={errors?.existing_exp_date}
              />
            </InputWrapper>
            </>)}
            <InputWrapper>
              <DatePicker
                label={"Manufacturing Month"}
                size={"medium"}
                views={["year", "month"]}
                inputFormat={"MMMM YYYY"}
                outputFormat={"YYYY-MM"}
                value={
                  manf_month || (reg_date && reg_date[0] + "-" + reg_date[1])
                }
                disableFuture
                error={errors?.manf_month}
                handleChange={(val) => {
                  setFormData({ ...formData, manf_month: val });
                  onChangeValidate("manf_month", val);
                }}
              />
            </InputWrapper>
          </CustomWrapper>
          <InputWrapper
            css={`
              width: calc(50% - 10px);
            `}
          >
            <DatePicker
              label='Registration Date'
              size={"medium"}
              value={registration_date}
              disableFuture
              // maxDate={moment(manf_month, "YYYY-MM")}
              minDate={moment(manf_month, "YYYY-MM")}
              handleChange={(val) => {
                setFormData({
                  ...formData,
                  registration_date: val,
                });
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <RadioButton
              items={yesNoItems}
              label='Did you make a claim last year'
              defaultValue={
                is_claim_made_last_year || requestData?.is_claim_made_last_year
              }
              onChange={(val) => {
                setFormData({ ...formData, is_claim_made_last_year: val });
              }}
            />
          </InputWrapper>
          {is_claim_made_last_year === "N" &&
            requestData?.policy_expired_before_90_days !== "Y" && (
              <InputWrapper>
                <NCB
                  registration_date={registration_date}
                  label={
                    <>
                      Select your existing No Claim Bonus
                      <br />
                      (NCB)
                    </>
                  }
                  value={formData?.prev_ncb}
                  onClick={(val) => {
                    setFormData({ ...formData, prev_ncb: val });
                  }}
                />
              </InputWrapper>
            )}
          <div
            css={`
              display: flex;
              justify-content: flex-end;
              & > div {
                width: 70%;
                @media (max-width: 576px) {
                  width: 100%;
                }
              }
            `}
          >
            <ButtonsWrapper>
              <Button secondary onClick={() => onClose()}>
                Cancel
              </Button>
              <Button primary onClick={onSubmit}>
                {isLoading ? (
                  <CircularProgress size={"16px"} style={{ color: "#fff" }} />
                ) : (
                  "Apply"
                )}
              </Button>
            </ButtonsWrapper>
          </div>
        </FormWrapper>
      </Dialog>
    </>
  );
};

export default EditPolicyDetails;
const CustomWrapper = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 576px) {
    width: 100%;
    flex-wrap: wrap;
    gap: unset;
  }
  & > div {
    flex: 1 1;
    @media (max-width: 576px) {
      width: 100%;
      flex: unset;
    }
  }
`;
